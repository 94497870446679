import React from 'react';
import banner from "../img/talleres-formativos-agricultura-digitalizacion-300124.jpg";
import footer from "../img/footer-talleres.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Agricultura y digitalización. Hacia una agricultura de datos" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-info centrado" role="alert">
						<h5>Agricultura y digitalización. Hacia una agricultura de datos</h5>
						<h6>JORNADAS DE DIGITALIZACIÓN EN EL SECTOR AGROALIMENTARIO, evento organizado por el Instituto para la Competitividad Empresarial de Castilla y León (ICE) y El Centro Tecnológico Agrario y Agroalimentario ITAGRA.CT, en el marco del Programa de Capacitación y Apoyo a la I+D+i Empresarial “Centr@Tec”.</h6>
						<h7><strong>Fecha y horario:</strong> 30 y 31 de enero de 2024, de 09:00 a 14:00</h7><br></br>
						<h7><strong>Lugar:</strong> Centro de Formación Agraria de Viñalta 34005 Palencia</h7>
						<hr></hr>
						<strong>Objetivos de la Jornada:</strong>
						<p>El objetivo de la jornada es dar a conocer las aplicaciones de estas tecnologías en unos sectores fundamentales a nivel nacional como son el agroalimentario e industrial, para poner en valor nuevas oportunidades de negocio y emprendimiento, atracción de talento, y promoción de las líneas de investigación.</p>
					</div>
				</div>
			</div>
			<div className="card text-white bg-info w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda día 30:</h5>
						<li><strong>09:00 - 9:30 Recepción. Entrega acreditaciones.</strong>
						</li>
						<li><strong>09:30 - 09:45 Apertura de la jornada.</strong>
						</li>
						<li><strong>09:45 - 10:15 Necesidades de la digitalización al sector agrario.</strong><br></br>
							<i>Raúl Azpeleta hierro (Unión de pequeños agricultores. Upa)</i>
						</li>
						<li><strong>10:15 - 10:45 “Cómo aprovechar la variabilidad del suelo en la gestión eficiente de los cultivos”.</strong><br></br>
							<i>Jorge Blanco. Responsable área i+d (Greenfield Technologies)</i>
						</li>
						<li><strong>10:45 - 11:15 Evolución de la agricultura de precisión en Álava a través de diferentes casos de estudio y estrategias de adopción.</strong><br></br>
							<i>Asier Uribeetxebarria. Doctor en Agricultura de Precisión (NEIKER BRTA)</i>
						</li>
						<li><strong>11:15 - 11:45 Pausa café</strong>
						</li>
						<li><strong>11:45 - 12:15 Field view. Agricultura en la palma de tu mano.</strong><br></br>
							<i>Amaya Pastrana González (Bayer Digital Activation Speciallist)</i><br></br>
							<i>Nuria Andrés del Campo (Bayer Digital Activation Speciallist)</i>
						</li>
						<li><strong>12:15 - 13:00 “Teledetección para el manejo agronómico”</strong><br></br>
							<i>Carmen Plaza. Ing. Agrónoma. Responsable del Servicio Irrimaps (Agrisat)</i><br></br>
							<i>María Calera. Ing. Agrónoma. Responsable del Servicio Fertimaps (Agrisat)</i>
						</li>
						<li><strong>13:00 - 13:30 Herramientas digitales del ecosistema de innovación de Cajamar. Ejemplo cuaderno digital de explotaciones, CXTIERRA.</strong><br></br>
							<i>Jesús Regodón Ruiz (Responsable Herramienta Digitales (Plataforma Tierra))</i>
						</li>
						<li><strong>13:30 - 14:00 Mesa redonda</strong> “Digitalización el gran reto de la agricultura”<br></br>
						</li>
						<hr></hr>
						<h5 className="card-title centrado">Agenda día 31:</h5>
						<li><strong>09:00 - 9:30 Recepción. Entrega acreditaciones.</strong>
						</li>
						<li><strong>09:30 - 09:45 Apertura de la jornada.</strong>
						</li>
						<li><strong>09:45 - 10:15 Introducción de tecnología y necesidades de innovación</strong><br></br>
							<i>Sergio Menéndez. Coordinador del Grupo de Innovación de Campo. (Grupo AN)</i>
						</li>
						<li><strong>10:15 - 10:45 "Cosechando datos de maíz forrajero"</strong><br></br>
							<i>Javier Bueno. Doctor Ingeniero Agrónomo. Profesor titular Universidad de Santiago de Compostela</i>
						</li>
						<li><strong>10:45 - 11:15 CESENS como herramienta de gestión agrícola. Gestión hídrica de cultivos, modelos predictivos de enfermedades.</strong><br></br>
							<i>Nicolás Molina (Ingeniero Agrónomo CESENS)</i>
						</li>
						<li><strong>11:15 - 11:45 Pausa café</strong>
						</li>
						<li><strong>11:45 - 12:15 Uso práctico de la digitalización en la protección de cultivos.</strong><br></br>
							<i>Nacho Tobalina (Bayer Crop Science)</i>
						</li>
						<li><strong>12:15 - 12:45 No lo llames digitalización, llámalo agronomía. Experiencias reales en agricultura.</strong><br></br>
							<i>Jorge Miñón (AGRAE)</i>
						</li>
						<li><strong>12:45 - 13:15 Sembrando el futuro.</strong><br></br>
							<i>Marcos Esteve. Ingeniero Mecánico y Joven Agricultor.</i>
						</li>
						<li><strong>13:15 - 14:00 Mesa redonda:</strong>“Perspectiva actual de la digitalización del sector agrario”<br></br>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 30 y 31 de enero de 2024, de 09:00 a 14:00</p>
					<p className="card-text-left"> Centro de Formación Agraria de Viñalta 34005 Palencia</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/1205" width="95%" height="900px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<img src={footer} className="banner" alt="Agricultura y digitalización. Hacia una agricultura de datos" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i className="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i className="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home